import axios from "axios";
import Choices from 'choices.js';
import {inlineLoaderNode} from "@/consts";

class CheckoutAddressesStep {
    private sameAddress: any;
    private shippingMethod: any;
    constructor() {
        this.addEventListeners();
    }

    private addEventListeners() {
        this.sameAddress = document.querySelector('[data-js-checkout-addresses-same-address]');
        if (this.sameAddress) {
            const sameAddressCheckbox = this.sameAddress.querySelector('input');
            if (sameAddressCheckbox) {
                sameAddressCheckbox.addEventListener('change', () => {
                    this.addLoader(this.sameAddress);
                    this.refreshForm();
                });
            }
        }
        this.shippingMethod = document.querySelector('[data-js-checkout-addresses-shipping-method]');
        if (this.shippingMethod) {
            const shippingMethodSelect = this.shippingMethod.querySelector('select');
            if (shippingMethodSelect) {
                shippingMethodSelect.addEventListener('change', () => {
                    this.addLoader(this.shippingMethod);
                    this.refreshForm();
                });
            }
        }
    }

    private addLoader(element: any) {
        element.insertAdjacentHTML('afterend',`
            <div class="pr-absolute pr-pin pr-flex pr-items-center pr-justify-center pr-bg-white-trans">
                ${inlineLoaderNode}
            </div>
        `);
    }

    private reInit() {
        const selects = document.querySelectorAll('select.select--custom');
        [].forEach.call(selects, (select) => {
            new Choices(select, {
                searchEnabled: false,
                placeholder: true,
                itemSelectText: '',
                shouldSort: false,
            });
        });
    }

    private startLoadingAnimation() {

    }

    private stopLoadingAnimation() {

    }
    private refreshForm() {
        this.startLoadingAnimation();
        const fields: any = {};
        const data: any = new FormData();
        const form = document.querySelector('form');
        [].forEach.call((form as HTMLFormElement).elements, (field: any) => {
            if (!/_token/.test(field.name) && field.tagName !== 'BUTTON') {
                if ((field.type !== 'checkbox' || (field.type === 'checkbox' && field.checked)) &&
                    (field.type !== 'radio' || (field.type === 'radio' && field.checked))
                ) {
                    fields[field.name] = field.value;
                    data.append([field.name], field.value);
                }
            }
        });
        axios(({
            method: 'post',
            url: window.location.href,
            data: data,
            config: {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-Requested-With': 'XMLHttpRequest',
                }
            }
        } as any))
            .then((response: any) => {
                this.stopLoadingAnimation();
                const parser = new DOMParser();
                const htmlDoc = parser.parseFromString(response.data, 'text/html');
                const form = document.querySelector('form');
                const shadowForm = htmlDoc.querySelector('form');
                if (form && shadowForm) {
                    (form as any).parentNode.replaceChild(shadowForm, form);
                    this.reInit();
                    this.addEventListeners();
                }
            })
            .catch((err) => log.error(err));

    }
}
new CheckoutAddressesStep();
