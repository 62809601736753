import tippy from 'tippy.js';

tippy('.js-navigation-tippy', {
    content: "Non accessible dans votre abonnement",
    placement: "bottom",
});

tippy('.js-card-disabled-tippy', {
    content: "Non accessible dans votre abonnement",
    placement: "bottom",
});
