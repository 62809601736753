const openButton = (document as any).querySelector('.js-navigation-open');
const closeButton = (document as any).querySelector('.js-navigation-close');
const navigation = (document as any).querySelector('.js-navigation');

if (navigation) {
    openButton.addEventListener('click', () => {
        navigation.classList.toggle('navigation--isVisible');
    });

    closeButton.addEventListener('click', () => {
        navigation.classList.remove('navigation--isVisible');
    });
}


