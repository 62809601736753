// @ts-ignore
import axios from "axios";
import {inlineLoaderNode} from "@/consts";
//@ts-ignore;
import uuid from 'uuid/v1';
import Choices from "choices.js";

export default class ProfileEditAddresses {
    private loaders: any = [];
    private form: any = null;
    private addressesCheckbox: any = null;
    private addressesShippingFieldsEl: any = null;
    constructor() {
        this.initForm();
        this.initEvent();
    }

    private addLoader(element: any) {
        const id = uuid();
        element.insertAdjacentHTML('afterend',`
            <div id="${id}" class="pr-absolute pr-pin pr-flex pr-items-center pr-justify-center pr-bg-white-trans">
                ${inlineLoaderNode}
            </div>
        `);
        return id;
    }

    public initForm() {
        this.form = document.querySelector('[data-profile-edit-adresses]');
        if (this.form) {
            this.addressesCheckbox = this.form.querySelector('[data-profile-edit-addresses-shipping-checkbox] input');
            this.addressesShippingFieldsEl = this.form.querySelector('[data-profile-edit-addresses-shipping]');
        }
    }

    public initEvent() {
        if (this.addressesCheckbox) {
            this.addressesCheckbox.addEventListener('change', () => {
                this.updateData();
            });
        }
    }

    private startLoadingAnimation() {
        const loaderId = this.addLoader(this.closest(this.addressesCheckbox, '[data-profile-edit-addresses-shipping-checkbox]'));
        this.loaders.push(loaderId);
    }

    private stopLoadingAnimation() {
        this.loaders.forEach((loaderId: string) => {
            const loaderEl = document.getElementById(loaderId);
            if (loaderEl) {
                (loaderEl as any).parentNode.removeChild(loaderEl);
            }
        });
        this.loaders = [];
    }

    private updateData() {
        this.startLoadingAnimation();
        const fields: any = {};
        const data: any = new FormData();
        const form = document.querySelector('form');
        [].forEach.call((form as HTMLFormElement).elements, (field: any) => {
            if (!/_token/.test(field.name) && field.tagName !== 'BUTTON') {
                if ((field.type !== 'checkbox' || (field.type === 'checkbox' && field.checked)) &&
                    (field.type !== 'radio' || (field.type === 'radio' && field.checked))
                ) {
                    fields[field.name] = field.value;
                    data.set([field.name], field.value);
                }
            }
        });
        axios(({
            method: 'post',

            url: window.location.href,
            data: data,
            config: {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-Requested-With': 'XMLHttpRequest',
                }
            }
        } as any))
            .then((response: any) => {
                this.stopLoadingAnimation();
                const parser = new DOMParser();
                const htmlDoc = parser.parseFromString(response.data, 'text/html');
                const shadowFields = htmlDoc.querySelector('[data-profile-edit-addresses-shipping]');
                if (shadowFields && this.addressesShippingFieldsEl) {
                    this.addressesShippingFieldsEl.innerHTML = shadowFields.innerHTML;
                    this.initForm();
                    this.initEvent();
                }
                if (document.querySelector('[data-profile-edit-addresses-shipping] select')) {
                    new Choices('[data-profile-edit-addresses-shipping] select', {
                        searchEnabled: false,
                        placeholder: true,
                        itemSelectText: '',
                        shouldSort: false,
                    });
                }
            })
            .catch((err) => log.error(err));
    }

    private closest (el: any, selector: any, stopSelector: any = null) {
        var retval = null;
        while (el) {
            if (el.matches(selector)) {
                retval = el;
                break
            } else if (stopSelector && el.matches(stopSelector)) {
                break
            }
            el = el.parentElement;
        }
        return retval;
    }
}
new ProfileEditAddresses();
