



















































































































































































































































































































































































































































































































































































































































































import {

    Vue,

    Component,

    Watch,

    Prop

} from "vue-property-decorator";

import StyleguideItem from './StyleguideItem.vue';

import StyleguideTreeMenu from './StyleguideTreeMenu.vue';

import Notifications from 'vue-notification';

import * as _ from 'lodash';

Vue.use(Notifications);

@Component({

    components: {

        StyleguideItem,

        StyleguideTreeMenu,

    },

})

export default class Styleguide extends Vue {

    /**

     * @property {object} styleguide - [DSS Output Object]{@link https://github.com/DSSWG/DSS#example-output}

     */

    @Prop({required: true}) styleguide: any;

    sections: any[] = [];

    selected: any = null;

    cmOptions: any = {

        tabSize: 4,

        styleActiveLine: true,

        mode: 'htmlmixed',

        theme: 'monokai',

        lineNumbers: true,

        line: true,

        readOnly: true,

        lineWrapping: true,

    };

    state: any = {

        fullWidth: true,

        menuVisible: false

    };

    isMounted: boolean = false;



    @Watch('state', { deep: true })

    onStateChange() {

        localStorage.setItem('styleguideStates', JSON.stringify(this.state));

    }



    @Watch('selected')

    onSelectedChange() {

        let path = null;

        if(this.selected) {

            path = _.find(this.sections, (section: any) => section.id === this.selected).path;

        }

        localStorage.setItem('styleguidePath', JSON.stringify(path));

    }



    mounted() {

        this.handleStates();

        this.createRelationTree();

        this.navigateTo();

        this.isMounted = true;

    }



    navigateTo() {

        if(localStorage.getItem('styleguidePath') && JSON.parse(localStorage.getItem('styleguidePath'))) {

            const section = _.find(this.sections, (section: any) => section.path === JSON.parse(localStorage.getItem('styleguidePath')));

            if(section) {

                this.selected = section.id;

            }

        }

    }



    handleStates() {

        const w = window;

        const d = document;

        const e = d.documentElement;

        const g = d.getElementsByTagName('body')[0];

        const width = w.innerWidth||e.clientWidth||g.clientWidth;

        if(width <= 768) {

            this.state.fullWidth = true

        }

        if (localStorage.getItem('styleguideStates')) {

            this.state = {

                ...this.state,

                ...JSON.parse(localStorage.getItem('styleguideStates'))

            }

        }

    }

    createRelationTree() {

        let id = 0;

        // create sections

        this.styleguide.blocks.forEach((block: any) => {

            if (!block.section) {

                block.section = ['Other', block.name ? block.name : 'Nameless'];

            }

            _.each(block.section, (section: string, index) => {

                let path = '';

                let level = _.indexOf(block.section, section);

                for (let i = 0; i < level + 1; i++) {

                    if (path === '') {

                        path = encodeURI(block.section[i].toLocaleLowerCase());

                    } else {

                        path += '/' + encodeURI(block.section[i].toLocaleLowerCase());

                    }

                }

                const newSection = { path, level, id: 0, name: section };

                if (

                    !_.some(this.sections, (arraySection: any) =>

                        arraySection.name === newSection.name && arraySection.level === newSection.level && arraySection.path === newSection.path)

                ) {

                    newSection.id = id;

                    this.sections.push(newSection);

                    id++;

                }

            });

        });



        // create children relations

        this.sections.forEach((section: any) => {

            const children = _.filter(

                this.sections,

                (filterSection: any) => filterSection.path.startsWith(section.path) && filterSection.id !== section.id

            );

            section.children = children.map((child: any) => child.id);

        });



        // create parent relations

        this.sections.forEach((section: any) => {

            if(section.level > 0) {

                const parentLevelSections = _.filter(

                    this.sections, (filterSection: any) => filterSection.level === section.level - 1

                );

                _.each(parentLevelSections, (parentLevelSection) => {

                   if (_.includes(parentLevelSection.children, section.id)) {

                       section.parent = parentLevelSection.id;

                   }

                });

            }

        });



        // create parents relations

        this.sections.forEach((section: any) => {

            if(section.level > 0) {

                section.parents = [];

                const pathArray = section.path.split('/');

                const pathLength = pathArray.length;

                console.log(section.path, pathArray, pathLength);

                for (let i = 0; i < pathLength - 1; i++) {

                    const anyParent = _.find(this.sections, (findSection: any) => {

                       let rangeArray = pathArray.slice(0, i+1);

                       let targetPath = rangeArray.join('/');

                       return findSection.path === targetPath;

                    });

                    section.parents.push(anyParent.id);

                }

            }

        });

        this.sections = _.orderBy(this.sections, [(section: any) => section.name], ['desc']).reverse();

    }



    copyToClipboard(text: any) {

        if (document.queryCommandSupported && document.queryCommandSupported("copy")) {

            const textarea = document.createElement("textarea");

            textarea.textContent = text;

            textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.

            document.body.appendChild(textarea);

            textarea.select();

            try {

                return document.execCommand("copy");  // Security exception may be thrown by some browsers.

            } catch (ex) {

                console.warn("Copy to clipboard failed.", ex);

                return false;

            } finally {

                document.body.removeChild(textarea);

            }

        }

        // (this as any).$notify({

        //     group: 'copypasta',

        //     text: 'Copied to clipboard!'

        // });

    }



    getLinks(level: number = 0) {

        return _.filter(this.sections, (section: any) => {

            return section.level === level;

        });

    }



    get selectedBlocks() {

        if (this.selected !== null) {

            const selectedSection = _.find(this.sections, (findSection: any) => findSection.id === this.selected);

            return _.filter(this.styleguide.blocks, (block: any) => {

                return block.section[selectedSection.level] === selectedSection.name;

            });

            // if(selectedSection.parents) {

            //     let selectedSectionParents = selectedSection.parents

            //         .map((parentID: any) => _.find(this.sections, (section: any) => section.id === parentID));

            //     return _.filter(this.styleguide.blocks, (block: any) => {

            //         return _.some(selectedSectionParents, (someSection) => {

            //             return someSection.name === block.section[someSection.level];

            //         });

            //     });

            // } else {

            //     return _.filter(this.styleguide.blocks, (block: any) => {

            //         return block.section[0] === selectedSection.name;

            //     });

            // }

        }

        return this.styleguide.blocks;

    }

}

Vue.component('Styleguide', Styleguide);

