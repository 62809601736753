// @ts-ignore
import * as Swiper from 'swiper/dist/js/swiper.js';
const selector = '.js-image-slider';
export default class ImageSlider {
    private id: any = null;
    private instance: any = null;
    constructor(elementOrSelector: any, options = {}) {
        if (!elementOrSelector) {
            throw new Error('Card Slider requires a Selector or a HTML Element.');
        }
        this.instanciate(elementOrSelector, options);
    }

    private instanciate(elementOrSelector: any, options: any) {
            this.instance = new Swiper.default(elementOrSelector, {
                ...options,
            });
    }
}
[].forEach.call(document.querySelectorAll(selector), (element: HTMLElement) => {
    const id = element.getAttribute('data-id') || null;
    const option = element.getAttribute('data-option') ? JSON.parse((element.getAttribute('data-option') as any)) : {};
    return new ImageSlider(element, {
        navigation: {
            prevEl: document.querySelector(`[data-prev="${id}"]`),
            nextEl: document.querySelector(`[data-next="${id}"]`),
        },
        pagination: {
            el: document.querySelector(`[data-pagination="${id}"]`),
            clickable: true,
        },
        slidesPerView: 1,
        spaceBetween: 24,
        ...option,
    });
});
