// @ts-ignore;
import 'clampify';

declare const $clampify: any;
const init = (selector: any = null) => {
    [].forEach.call((selector
        ? document.querySelector(selector)
        : document).querySelectorAll('.js-clamp'), (element: any) => {
        if (element.dataset.children === 'true') {
            const childrens = element.querySelectorAll('p');
            [].forEach.call(childrens, (child) => {
                ($clampify as any)(child, {
                    maxLines: 3,
                    endsWith: '...',
                    autoUpdate: true,
                });
            });
        }
        ($clampify as any)(element, {
            maxLines: 3,
            endsWith: '...',
            autoUpdate: true,
        });
    });
};
init();
export default init;
