import axios from 'axios';

const body = (document as any).querySelector('.body');

const scrollToFilters = () => {
    const isIE11 = !!(window as any).MSInputMethodContext && !!(document as any).documentMode;
    const essentialsHeight = (document as any).querySelector('.section__essential__category') ? (document as any).querySelector('.section__essential__category').clientHeight : 0;
    const heroHeight = (document as any).querySelector('.js-hero-filters').clientHeight;
    if (isIE11) {
        window.scrollTo(0, heroHeight + essentialsHeight);
    } else {
        window.scrollTo({
            top: heroHeight + essentialsHeight,
            behavior: 'smooth',
        });
    }
};

if (body && body.classList.contains('category__list')) {
    if (location.search !== '') {
        scrollToFilters();
    }
    const cancelButton = document.querySelector('.js-cancelFilterButton');
    const filterButton = document.querySelector('.js-filterButton');
    const filterLocation = document.querySelector('.js-filterLocation');
    const filterSelect = document.querySelector('.js-filterSelect');

    const distanceASC = (document as any).querySelector('[data-value="distance-ASC"]');
    const distanceDESC = (document as any).querySelector('[data-value="distance-DESC"]');
    const locationUrlParams = (document as any).querySelector('[data-location-params]');

    if (distanceASC && distanceDESC && !locationUrlParams) {
        distanceASC.classList.add('pr-hidden');
        distanceDESC.classList.add('pr-hidden');
    }

    (document as any).querySelector('.choices').addEventListener('click', () => {
        if ((filterLocation as any).value === '') {
            (document as any).querySelector('[data-value="distance-ASC"]').classList.add('pr-hidden');
            (document as any).querySelector('[data-value="distance-DESC"]').classList.add('pr-hidden');
        }
    });

    const apiFilter = (sortedByValue: string, orderByValue: string, locationValue: string) => {
        let query: any;
        if (sortedByValue && orderByValue) {
            query = `?orderBy=${
                orderByValue
                }&sortedBy=${
                sortedByValue
                }${
                locationValue !== '' ? '&location=' + locationValue : ''
                }`;
        } else if (locationValue) {
            query = `?location=${locationValue}`;
        }
        axios.get(query)
            .then((response: any) => {
                (window as any).location = window.location.origin + window.location.pathname + query;
                // (filterButton as any).classList.remove('is-in-call');
            })
            .catch((err: any) => {
                (filterButton as any).classList.remove('is-in-call');
                (document as any).querySelector('.category__list__filter-errors')
                    .classList.add('is-visible');
                log.error(err);
            });
        (filterButton as any).classList.add('is-in-call');
    };

    if (filterSelect && filterLocation && filterButton) {
        filterButton.addEventListener('click', () => {
            const location = (filterLocation as any).value;
            const selectValue = (filterSelect as any).value.split('-');
            if (selectValue[0] && selectValue[1]) {
                apiFilter(selectValue[0], selectValue[1], location);
            } else if (location) {
                apiFilter('', '', location);
            }
        });
    }

    if (cancelButton) {
        (cancelButton as any).addEventListener('click', () => {
            (window as any).location = window.location.origin + window.location.pathname;
        });
    }
}
