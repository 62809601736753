class Product {
    private inputs: any = document.querySelectorAll('[data-js-product-input] input');
    private submitButton: any = document.querySelector('[data-js-product-submit]');
   constructor() {
       if (this.inputs.length > 0 && this.submitButton) {
           this.addEventListeners();
           this.checkSubmitable();
       }
   }

   private addEventListeners() {
        [].forEach.call(this.inputs, (input: any) => {
           input.addEventListener('change', () => {
               this.checkSubmitable();
           })
        });
   }

   private checkSubmitable() {
       const isSubmitable = [].some.call(this.inputs, (input: any) => {
          return Number(input.value) > 0;
       });
       if (isSubmitable) {
           this.submitButton.removeEventListener('click', this.preventEvent);
           this.submitButton.style.cssText = ``;
       } else {
           this.submitButton.addEventListener('click', this.preventEvent);
           this.submitButton.style.cssText = `
               opacity: .3;
               cursor: default;
           `;
       }
   }

   private preventEvent(e: any) {
       e.preventDefault();
   }
}
new Product();
