import ready from "@ryanmorr/ready";

let passWordInputs = document.querySelectorAll('input[type=password]');

passWordInputs.forEach((elem, i) => {
    addEyeIcon(elem, i);
})

ready("[data-password='']", (e) => {
    showPassword();
})

function addEyeIcon(elem, index) {
    let button = document.createElement("i");
    button.className = "icon ion-md-eye ion-md-eye-show-password togglePassword absolute";
    elem.parentNode.appendChild(button);
}

function showPassword() {
    const togglePasswords = document.querySelectorAll('.togglePassword');
    const passwords = [...togglePasswords].map(e => e.parentElement);

    passwords.forEach(e => {
        const button = e.querySelector('.togglePassword');
        const input = e.querySelector('input[type="password"]');
        button.addEventListener('click', () => {
            if (input.getAttribute('type') === 'password') {
                input.setAttribute('type', 'text');
                button.classList.remove('ion-md-eye');
                button.classList.add('ion-md-eye-off');
            } else {
                input.setAttribute('type', 'password')
                button.classList.remove('ion-md-eye-off');
                button.classList.add('ion-md-eye');
            }
        })
    })
}








