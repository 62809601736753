// @ts-ignore
import $ from 'jquery';
import axios from "axios";
import {inlineLoaderNode} from "@/consts";
//@ts-ignore;
import uuid from 'uuid/v1';
let collectionType = require('./modules/collection-type');
export default class CheckoutPaymentStep {
    private giftCardsContainer = null;
    private savedCardsSelect = null;
    private creditsInput: any = null;
    private checkCheckbox: any = null;
    private loaders: any = [];
    constructor() {
        this.initGiftCards();
        this.initCredits();
    }

    private addLoader(element: any) {
        const id = uuid();
        element.insertAdjacentHTML('afterend',`
            <div id="${id}" class="pr-absolute pr-pin pr-flex pr-items-center pr-justify-center pr-bg-white-trans">
                ${inlineLoaderNode}
            </div>
        `);
        return id;
    }

    private startPriceLoadingAnimation() {
        const loaderId = this.addLoader((document as any).querySelector('[data-js-checkout-cart-price-container]').firstElementChild);
        this.loaders.push(loaderId);
    }

    private stopPriceLoadingAnimation() {

    }

    private startLoadingAnimation() {
        this.startPriceLoadingAnimation();
        const loaderId = this.addLoader((this.giftCardsContainer as any)[0]);
        this.loaders.push(loaderId);
    }

    private stopLoadingAnimation() {
        this.loaders.forEach((loaderId: string) => {
           const loaderEl = document.getElementById(loaderId);
           if (loaderEl) {
               (loaderEl as any).parentNode.removeChild(loaderEl);
           }
        });
        this.loaders = [];
    }

    public cardDedup(validateButton: any, code: string){
        const cards = $(document).find('input[id$="_giftCard"]');
        let found = 0;
        for(const card of cards) {
            if(card.value.toLowerCase() === code.toLowerCase()) {
                found++
                if(found > 1) break
            }
        }

        const error = validateButton.parents('.payment-checkout__giftCard').find('.payment-checkout__giftCard__dup-error')
        if(found > 1) {
            error.show()
            validateButton.attr('disabled', 'disabled')
        }
        else {
            error.hide()
            validateButton.removeAttr('disabled')
        }
    } 

    public handleCardCodeKeyUp(container: any) {
        let field = container.find('input[id$="_giftCard"]');
        let button = container.find('.button');
        let amountField = container.find('.payments-checkout__inline--input input:not([disabled="disabled"])');
        const ref = this

        field.on('input', () => {
            let code = field.val().split("-").join(""); // remove hyphens

            if (code.length > 0) {
                code = code.match(new RegExp('.{1,4}', 'g')).join("-");
            }
            field.val(code);

            ref.cardDedup(button, code)
        });
        button.on('click', () => {
            this.validateGiftCard();
        });
        if(amountField) {
            amountField.on('change', () => {
               this.validateGiftCard();
            });
        }
    }

    private initCredits() {
        this.creditsInput = (document as any)
            .querySelector('[data-js-checkout-payment-step-credits] input');
        this.checkCheckbox = (document as any)
            .querySelector('[data-js-checkout-payment-step-checks-checkbox] input');
        if (this.creditsInput) {
            this.creditsInput.addEventListener('change', () => {
                this.validateCredits();
            });
        }
        if (this.creditsInput) {
            this.checkCheckbox.addEventListener('change', () => {
                if (!this.checkCheckbox.checked) {
                    const giftCards = document.querySelectorAll('.payment-checkout__giftCard');
                    [].forEach.call(giftCards, (element: any) => {
                        element.parentNode.removeChild(element);
                    });
                }
                this.validateCredits();
            });
        }
    }

    private initGiftCards() {
        if ($('#purchase_order_payments_giftCards').length === 0) return
        this.giftCardsContainer = $('#purchase_order_payments_giftCards')

        const { addFieldWithValue } = collectionType(this.giftCardsContainer, 'Ajouter une carte cadeau', null, false, true, 
            [this.handleCardCodeKeyUp.bind(this)],
            [this.validateGiftCard.bind(this)]
        );
        //@ts-ignore;
        const ref = this
        $(document).off('click', '#add-saved-gift-cards')
        $(document).on('click', '#add-saved-gift-cards', function() {
            //@ts-ignore;
            const select = $('#saved-gift-cards')

            //avoid duplicates with non validated cards
            const value = select.val()
            const cards = $(document).find('input[id$="_giftCard"]');
            let duplicate = false
            for(const card of cards) {
                if(card.value.toLowerCase() === value.toLowerCase()) {
                    duplicate = true
                    break;
                }
            }
            if(!duplicate) {
                addFieldWithValue(value)
                select.val([]);
            }

            ref.validateGiftCard();
        })
    }

    private updateInfos(shadowDoc: any) {
        const priceContainer = document.querySelector('[data-js-checkout-cart-price-container]');
        const infoContainer = document.querySelector('[data-js-checkout-template-info]');
        const selectSavedGiftCards = document.querySelector('#saved-gift-cards');

        const shadowPriceContainer = shadowDoc.querySelector('[data-js-checkout-cart-price-container]');
        const shadowInfoContainer = shadowDoc.querySelector('[data-js-checkout-template-info]');
        const shadowSelectSavedGiftCards = shadowDoc.querySelector('#saved-gift-cards');

        (priceContainer as any).parentNode.replaceChild(shadowPriceContainer, priceContainer);
        (infoContainer as any).parentNode.replaceChild(shadowInfoContainer, infoContainer);
        (selectSavedGiftCards as any).parentNode.replaceChild(shadowSelectSavedGiftCards, selectSavedGiftCards);
    }

    private validateCredits() {
        this.startPriceLoadingAnimation();
        const fields: any = {};
        const data: any = new FormData();
        const form = document.querySelector('form');
        [].forEach.call((form as HTMLFormElement).elements, (field: any) => {
            if (!/_token/.test(field.name) && field.tagName !== 'BUTTON') {
                fields[field.name] = field.value;
                data.append([field.name], field.value);
            }
        });
        axios(({
            method: 'post',

            url: window.location.href,
            data: data,
            config: {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-Requested-With': 'XMLHttpRequest',
                }
            }
        } as any))
            .then((response: any) => {
                this.stopLoadingAnimation();
                const parser = new DOMParser();
                const htmlDoc = parser.parseFromString(response.data, 'text/html');
                this.updateInfos(htmlDoc);
            })
            .catch((err) => log.error(err));
    }

    private validateGiftCard() {
        this.startLoadingAnimation();
        const fields: any = {};
        const data: any = new FormData();
        const form = document.querySelector('form');
        [].forEach.call((form as HTMLFormElement).elements, (field: any) => {
            if (!/_token/.test(field.name) && field.tagName !== 'BUTTON') {
                fields[field.name] = field.value;
                data.append([field.name], field.value);
            }
        });
      
        axios(({
            method: 'post',
            url: window.location.href,
            data: data,
            config: {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-Requested-With': 'XMLHttpRequest',
                }
            }
        } as any))
            .then((response: any) => {
                this.stopLoadingAnimation();
                const parser = new DOMParser();
                const htmlDoc = parser.parseFromString(response.data, 'text/html');

                const giftCardsContainer = document.querySelector('#purchase_order_payments_giftCards');
                const shadowGiftCardsContainer = htmlDoc.querySelector('#purchase_order_payments_giftCards');
                if (giftCardsContainer && shadowGiftCardsContainer) {
                    (giftCardsContainer as any).parentNode.replaceChild(shadowGiftCardsContainer, giftCardsContainer);
                    this.initGiftCards();
                }
                this.updateInfos(htmlDoc);
                // if we want to do it one by one
                // const giftCard = this.closest(document.querySelector(`[name="${name}"]`), '.payment-checkout__giftCard');
                // const shadowGiftCard = this.closest(htmlDoc.querySelector(`[name="${name}"]`), '.payment-checkout__giftCard');
                // console.log(name, giftCard, shadowGiftCard);
                // if (giftCard && shadowGiftCard) {
                //     giftCard.parentNode.replaceChild(shadowGiftCard, giftCard);
                //     this.initGiftCards();
                // }
            })
            .catch((err) => log.error(err));
    }

    private closest (el: any, selector: any, stopSelector: any = null) {
        var retval = null;
        while (el) {
            if (el.matches(selector)) {
                retval = el;
                break
            } else if (stopSelector && el.matches(stopSelector)) {
                break
            }
            el = el.parentElement;
        }
        return retval;
    }
}
new CheckoutPaymentStep();
