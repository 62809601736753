import axios from 'axios';
import Choices from 'choices.js';
// @ts-ignore
import {ChoicesInstances} from '@/plugins/choices';
import {find, cloneDeep} from 'lodash';
export default class CheckoutProductRow {
    private readonly id: any;

    private price: number;
    private quantity: number;
    private total: number;
    private subventionUnitPrice: any = null;
    // private totalWasUpdated: boolean = false;

    private rowEl: any;
    private totalViewEl: any;
    private cartPriceView: any;
    private subventionEL: any;
    private subventionSelect: any;
    private subventionQuantitySelect: any;
    private subventionQuantitySelectChoicesInstance: any = null;
    private quantityInput: any;
    private deleteRowEL!: any;
    private deleteEl!: any;
    private formatter: any = new (Intl as any).NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
    });
    constructor(rowEl: any) {
        this.rowEl = rowEl;
        this.quantityInput = this.rowEl
            .querySelector('[data-js-checkout-product-row-quantity_input]')
            .querySelector('input');
        this.cartPriceView = document.querySelector('[data-js-checkout-cart-price]');

        this.id = this.rowEl.getAttribute('data-js-checkout-product-row');
        this.price = Number(this.rowEl.getAttribute('data-js-checkout-product-row-price'));
        this.quantity = Number(this.rowEl.getAttribute('data-js-checkout-product-row-quantity'));
        this.total = Number(this.rowEl.getAttribute('data-js-checkout-product-row-total'));
        this.totalViewEl = this.rowEl.querySelector('[data-js-checkout-product-row-total_view]');
        this.subventionEL = document.querySelector(`[data-js-checkout-product-row-subvention="${this.id}"]`);
        this.deleteRowEL = document.querySelector(`[data-js-checkout-product-row-delete_row="${this.id}"]`);
        this.deleteEl = document.querySelectorAll(`[data-js-checkout-product-row-delete="${this.id}"]`);
        if (this.totalViewEl) {
            this.totalViewEl.innerHTML = this.formatter.format(this.total);
        }
        this.addEventListeners();
        this.limitSubventionQuantitySelection();
    }

    private addEventListeners() {
        if (this.quantityInput) {
            this.quantityInput.addEventListener('change', (e: any) => {
                this.quantity = Number(e.target.value);
                this.limitSubventionQuantitySelection();
                this.updatePrice();
                this.updateData(true);
            });
        }
        if (this.deleteEl) {
            [].forEach.call(this.deleteEl, (element: HTMLElement) => {
                element.addEventListener('click', () => {
                    this.deleteRow();
                });
            });
        }
        this.addSelectEventListeners();
    }

    private addSelectEventListeners() {
        this.subventionSelect = this.subventionEL
            ? (this.subventionEL.querySelector('.table--subventions__item__subvention') as HTMLElement)
                .querySelector('select')
            : null;
        this.subventionQuantitySelect = this.subventionEL
            ? (this.subventionEL.querySelector('.table--subventions__item__applyQuantity') as HTMLElement)
                .querySelector('select')
            : null;
        if (this.subventionSelect) {
            this.subventionSelect.addEventListener('change', () => {
                this.updateData();
            });
        }
        if (this.subventionQuantitySelect) {
            const subventionQuantitySelectChoicesInstance = find(ChoicesInstances, (item: any) => item.element === this.subventionQuantitySelect);
            if (!this.subventionQuantitySelectChoicesInstance && subventionQuantitySelectChoicesInstance) {
                this.subventionQuantitySelectChoicesInstance = subventionQuantitySelectChoicesInstance.instance;
            }
            this.subventionQuantitySelect.addEventListener('change', () => {
                this.updateData();
            });
        }
        this.updateSubventionUnitPrice(this.total);
    }

    private startLoadingAnimation() {
        this.rowEl.querySelector('.table--entries__total')
            .classList.add('table--entries__total--loading');
        if (this.subventionEL) {
            this.subventionEL.querySelector('.table--subventions__items')
                .classList.add('table--subventions__items--loading');
        }
    }

    private stopLoadingAnimation() {
        this.rowEl.querySelector('.table--entries__total')
            .classList.remove('table--entries__total--loading');
        if (this.subventionEL) {
            this.subventionEL.querySelector('.table--subventions__items')
                .classList.remove('table--subventions__items--loading');
        }
    }

    private updatePrice() {
        const subventionQuantity = this.subventionQuantitySelect ? this.subventionQuantitySelect.value : null;
        if (subventionQuantity && Number(subventionQuantity) > 0) {
            if (Number(this.quantityInput.value) >= Number(subventionQuantity)) {
                this.total = subventionQuantity * this.subventionUnitPrice + (Number(this.quantityInput.value) - Number(subventionQuantity)) * this.price;
            } else {
                // NOTE: it depends if the subventions stack on 1 ticket or several
                // this.total = subventionQuantity * this.subventionUnitPrice;
                this.quantityInput.value = subventionQuantity;
                this.quantityInput.setAttribute('value', subventionQuantity);
                this.quantityInput.dispatchEvent(new Event('change'));
                this.quantity = subventionQuantity;
                return;
            }
        } else {
            this.total = this.quantity * this.price;
        }
        if (this.totalViewEl) {
            this.totalViewEl.innerHTML = this.formatter.format(this.total);
        }
        this.rowEl.setAttribute('data-js-checkout-product-row-price', this.price);
        this.rowEl.setAttribute('data-js-checkout-product-row-quantity', this.quantity);
        this.rowEl.setAttribute('data-js-checkout-product-row-total', this.total);

        const rows = document.querySelectorAll('[data-js-checkout-product-row]');
        const totalPrice = ([] as any)
            .map.call(rows, (mapElement: HTMLElement) => Number(mapElement
                .getAttribute('data-js-checkout-product-row-total')))
            .reduce((accumulator: string, currentValue: string) => accumulator + currentValue);
        if (this.cartPriceView) {
            this.cartPriceView.innerHTML = this.formatter.format(totalPrice);
        }
    }

    private updateData(ignoreResponse = false) {
        if (!ignoreResponse) {
            this.startLoadingAnimation();
        }
        const fields: any = {};
        const data: any = new FormData();
        const form = document.querySelector('form');
        [].forEach.call((form as HTMLFormElement).elements, (field: any) => {
            if (!/_token/.test(field.name) && field.tagName !== 'BUTTON') {
                fields[field.name] = field.value;
                data.append([field.name], field.value);
            }
        });
        axios(({
            method: 'post',
            url: window.location.href,
            data: data,
            config: {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-Requested-With': 'XMLHttpRequest',
                }
            }
        } as any))
            .then((response: any) => {
                if (!ignoreResponse) {
                    this.stopLoadingAnimation();
                    const parser = new DOMParser();
                    const htmlDoc = parser.parseFromString(response.data, 'text/html');
                    const shadowRowEl = this.closest(
                        htmlDoc.querySelector(`[name="${this.quantityInput.name}"]`),
                        '[data-js-checkout-product-row]');
                    const shadowRowId = shadowRowEl.getAttribute('data-js-checkout-product-row');
                    const shadowSubventionRow: any = htmlDoc.querySelector(`[data-js-checkout-product-row-subvention="${shadowRowId}"]`);
                    this.price = Number(shadowRowEl.getAttribute('data-js-checkout-product-row-price'));
                    this.quantity = Number(shadowRowEl.getAttribute('data-js-checkout-product-row-quantity'));
                    this.total = Number(shadowRowEl.getAttribute('data-js-checkout-product-row-total'));
                    this.updateSelects(shadowRowEl, shadowSubventionRow);
                    this.updatePrice();
                }
            })
            .catch((err) => log.error(err));
    }

    private updateSelects(shadowRowEl: HTMLElement, shadowSubventionRow: HTMLElement) {
        const subventionSelectNodeToReplace = this.closest(this.subventionSelect, '.choices');
        const subventionQuantitySelectNodeToReplace = this.closest(this.subventionQuantitySelect, '.choices');
        const subventionSelectName = this.subventionSelect.name;
        const subventionQuantitySelectName = this.subventionQuantitySelect.name;
        const shadowSubventionSelect = shadowSubventionRow.querySelector(`[name="${subventionSelectName}"]`);
        const shadowSubventionQuantitySelect = shadowSubventionRow.querySelector(`[name="${subventionQuantitySelectName}"]`);
        subventionSelectNodeToReplace.parentNode.replaceChild(shadowSubventionSelect, subventionSelectNodeToReplace);
        subventionQuantitySelectNodeToReplace.parentNode.replaceChild(shadowSubventionQuantitySelect, subventionQuantitySelectNodeToReplace);
        new Choices((shadowSubventionSelect as HTMLElement), {
            searchEnabled: false,
            placeholder: true,
            itemSelectText: '',
            shouldSort: false,
        });
        this.subventionQuantitySelectChoicesInstance = new Choices((shadowSubventionQuantitySelect as HTMLElement), {
            searchEnabled: false,
            placeholder: true,
            itemSelectText: '',
            shouldSort: false,
        });
        this.addSelectEventListeners();
        this.limitSubventionQuantitySelection();
    }

    private updateSubventionUnitPrice(total: any) {
        const subventionQuantity = this.subventionQuantitySelect ? this.subventionQuantitySelect.value : null;
        if (subventionQuantity && Number(subventionQuantity) > 0) {
            if ((Number(this.quantityInput.value) >= Number(subventionQuantity))) {
                this.subventionUnitPrice = (total - ((Number(this.quantityInput.value) - Number(subventionQuantity))*this.price))/Number(subventionQuantity);
            } else if ((Number(this.quantityInput.value) < Number(subventionQuantity))) {
                this.subventionUnitPrice = total / Number(this.quantityInput.value);
            }
        }
    }

    private limitSubventionQuantitySelection() {
        if (this.subventionQuantitySelect) {
            const options = cloneDeep(this.subventionQuantitySelectChoicesInstance._presetChoices);
            const newOptions: any[] = [];
            options.forEach((option: any) => {
                newOptions.push({
                    ...option,
                    disabled: Number(option.value) > this.quantity
                });
            });
            this.subventionQuantitySelectChoicesInstance
                .setChoices(newOptions, 'value', 'label', true);
        }
    };

    private closest (el: any, selector: any, stopSelector: any = null) {
        var retval = null;
        while (el) {
            if (el.matches(selector)) {
                retval = el;
                break
            } else if (stopSelector && el.matches(stopSelector)) {
                break
            }
            el = el.parentElement;
        }
        return retval;
    }

    private deleteRow() {
        const tbody = this.closest(this.rowEl, 'tbody');
        const rows = tbody.querySelectorAll('[data-js-checkout-product-row]');
        if (rows.length > 1) {
            this.rowEl.parentNode.removeChild(this.rowEl);
            if (this.subventionEL) {
                this.subventionEL.parentNode.removeChild(this.subventionEL);
            }
            this.deleteRowEL.parentNode.removeChild(this.deleteRowEL);
        } else {
            tbody.parentNode.removeChild(tbody);
        }
        const fields: any = {};
        const data: any = new FormData();
        const form = document.querySelector('form');
        [].forEach.call((form as HTMLFormElement).elements, (field: any) => {
            if (field.tagName !== 'BUTTON') {
                fields[field.name] = field.value;
                data.append([field.name], field.value);
            }
        });
        axios(({
            method: 'post',
            url: window.location.href,
            data: data,
            config: {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-Requested-With': 'XMLHttpRequest',
                }
            }
        } as any))
            .then(() => {
                window.location.reload();
            })
            .catch((err) => log.error(err));
    }
}
