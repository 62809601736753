import axios from 'axios';
import Clampify from '@/js/clampify';
// @ts-ignore
import Timeout from 'smart-timeout';

import L, { LatLngExpression, Map as LeafletMap} from 'leaflet'
import 'leaflet/dist/leaflet.css';
import { debounce } from 'lodash'

const localizedSearchBtn = document.querySelector('.js-searchLocalized');
const citySearch = document.querySelector('.js-citySearch')
const citySearchInput = document.querySelector('.js-citySearchInput') 
const search = document.querySelector('.js-searchLocalizedOverlay')
const searchMap = document.querySelector('#js-searchLocalizedMap')
const searchResult = document.querySelector('.js-searchResultLocalized');
const LIMIT_KM_SEARCH = 150
const DEFAULT_ZOOM_LVL = 10
const endpoint = window.location.origin

const defaultIcon = new L.Icon({
    iconUrl: require('../../../node_modules/leaflet/dist/images/marker-icon-2x.png'),
    iconSize: [16, 24],
    iconAnchor: [4, 4],
    popupAnchor: [0, -8]
  });

let hasMoved = false
let map: LeafletMap | null = null
const markers: L.Marker[] = []

const centerMapOnUserSearch = (searchValue: string) => {
    axios.get(`${endpoint}/searchLocation?query=${encodeURI(searchValue)}`)
    .then((response) => {
        (search as Element).classList.remove('js-search--loading');
        if (response.data && map) {
            const {data} = response.data
            map.setView([data.lat, data.lng], DEFAULT_ZOOM_LVL)
            Clampify('.js-searchResult');
        }
    }).catch((err) => {
        (search as Element).classList.remove('js-search--loading');
    })
}

const displayStatus = ({loading, noData, waitingAction} : {loading?: boolean, noData?: boolean, waitingAction?: boolean}) => {
    const elements = document.getElementsByClassName('search-status')
    for(const element of elements) element.className = (element.className).replace(/\s*active/g, "");

    if(loading){
        const el = document.getElementById('loading_result')
        if(el) el.className += " active";
        return
    }

    if(noData){
        const el = document.getElementById('search_city_instruct')
        if(el) el.className += " active";
        return
    }

    if(waitingAction){
        const el = document.getElementById('map_marker_action')
        if(el) el.className += " active";
        return
    }
}

const getWidthHeightInKM = () => {
    if(!map) return null
    const bounds = map.getBounds();
    const width = map.distance(bounds.getNorthWest(), bounds.getNorthEast()) / 1000;
    const height = map.distance(bounds.getNorthWest(), bounds.getSouthWest()) / 1000;

    return {
        width,
        height
    }
}

const resetSearch = () => {
    for(const marker of markers) {
        (map as LeafletMap).removeLayer(marker)
    }
    markers.length = 0
}

const searchAt = ({lat, lng}: {lat: number, lng: number}) => {
    displayStatus({loading: true})

    axios.get(`${endpoint}/searchAt?lat=${lat}&lng=${lng}&dist=${LIMIT_KM_SEARCH}`)
    .then((response) => {
        (search as Element).classList.remove('js-search--loading');
        resetSearch()
        const localizedItems = new Map()
        const {data} = response

        displayStatus(!data || data.length === 0 ? {noData: true} : {waitingAction: true})

        for(const product of data) {
            const {id, name, positions, thumbnail, slug, categories} = product['0']
            for(const position of positions) {
                const key = `${parseFloat(position.lat).toFixed(4)}${parseFloat(position.lng).toFixed(4)}`

                //leaflet won't set multiples marken at same location
                if(!localizedItems.has(key)) {
                    localizedItems.set(key, [])
                }

                localizedItems.get(key).push({
                    id,
                    name,
                    slug,
                    thumbnail,
                    position,
                    categories
                })
            }
        }

        for (const [key, value] of localizedItems) {
            const popupContent = value.map(
                (p: any)=>
                    `<a target="_blank" href="/product/${p.id}-${p.slug}">
                        <div class="map-popup__product">
                            <div class="map-popup__product__name">${p.name}</div>
                            <div class="map-popup__product__categories">
                                    ${
                                p.categories.map((cat: any)=>`<div class="map-popup__product__categories__category"><img src="data:image/svg+xml;base64,${cat.menuLogo}" title="${cat.name}"/></div>`).join('')
                                }
                            </div>
                        </div>
                    </a>`
                )
                .join('')

            markers.push(
                L.marker(
                    [value[0].position.lat, value[0].position.lng],
                    {
                        icon: defaultIcon,
                    }
                )
                .bindPopup(`
                <div class="map-popup">${popupContent}</div>`)
                .addTo(map as LeafletMap)
                .on('click', ()=>{
                    //@ts-expect-error
                    this.openPopup();
                })
                // .on('mouseover', function (e) {
                //     //@ts-expect-error
                //     this.openPopup();
                // })
            )
        }
    })
    .catch((err) => {
        (search as Element).classList.remove('js-search--loading');
        displayStatus({noData: true})
    })
}

const initMap = () => {
    map = L.map('js-searchLocalizedMap', {
        zoomSnap: 1,
        maxZoom: DEFAULT_ZOOM_LVL+8,
    }).setView([48.866667, 2.333333], DEFAULT_ZOOM_LVL)


//'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'map
    L.tileLayer('https://api.maptiler.com/maps/7a4ffd5e-29e7-4cd8-8889-81ccce127191/{z}/{x}/{y}.png?key=eYsmL6lSNb0eMw5YPaqt', {// @2x
        maxZoom: 19,
        tileSize: 512,
        zoomOffset: -1,
        crossOrigin: true
        // subdomains:['mt0','mt1','mt2','mt3'] //google ? 
    }).addTo(map);

    // L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //     maxZoom: 19,
    //     attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    // }).addTo(map);

    if ("geolocation" in navigator) {
        displayStatus({loading: true})
        navigator.geolocation.getCurrentPosition((position) => {
            if(!hasMoved) (map as LeafletMap).setView([position.coords.latitude, position.coords.longitude])
            displayStatus({waitingAction: true})
        }, () => {
            displayStatus({noData: true})
        });
    }

    map.on('zoomend', function() {
        if(!map) return
        hasMoved = true

        const dist = getWidthHeightInKM() as {width: number}
        if(!dist || dist.width > LIMIT_KM_SEARCH) {
            resetSearch()
            return
        }

        searchAt(map.getCenter())
    });

    map.on('moveend', function() {
        if(!map) return
        hasMoved = true

        const dist = getWidthHeightInKM() as {width: number}
        if(!dist || dist.width > LIMIT_KM_SEARCH) {
            return
        }

        searchAt(map.getCenter())
    });
}

const toggleSearch = () => {
    if (search) {
        search.classList.toggle('js-search--visible');
        if (search.classList.contains('js-search--visible')) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        if(!map) {
            initMap()
        }

        //in case initMap fails
        if(map) map.invalidateSize(true);
    }
};

if (localizedSearchBtn && search && searchMap) {
    const searchClose = search.querySelector('.js-searchLocalizedClose');
    localizedSearchBtn.addEventListener('click', toggleSearch);
    if (searchClose) {
        searchClose.addEventListener('click', toggleSearch);
    }

    if(citySearch && citySearchInput){
        const debouncedSearch = debounce(() => {
            search.classList.add('js-search--loading');
            centerMapOnUserSearch((citySearchInput as HTMLInputElement).value)
        }, 500)
        citySearchInput.addEventListener('keypress', debouncedSearch)
        citySearchInput.addEventListener('keydown', (e) => {
            if ((e as KeyboardEvent).key === 'Enter') {
                search.classList.add('js-search--loading');
                centerMapOnUserSearch((citySearchInput as HTMLInputElement).value)
            }
        })
        citySearch.addEventListener('click', ()=>{
            search.classList.add('js-search--loading');
            centerMapOnUserSearch((citySearchInput as HTMLInputElement).value)
        })
    }
}



