const listsToggle = document.querySelectorAll('.js-navigationToggle');
const closestParent = (el: any, selector: any, stopSelector: any = null) => {
    let retval = null;
    while (el) {
        if (el.matches(selector)) {
            retval = el;
            break;
        } else if (stopSelector && el.matches(stopSelector)) {
            break;
        }
        el = el.parentElement;
    }
    return retval;
};
[].forEach.call(listsToggle, (item: any) => {
    let selectedItem: any;
    item.addEventListener('click', (e: any) => {

        const listItem = closestParent(item, '.js-navigationItem');
        selectedItem = listItem;

        const listToggleOpen = document.querySelectorAll('.js-navigationItem--opened');
        [].forEach.call(listToggleOpen, (openItem: any) => {
            if (openItem !== selectedItem) {
                openItem.classList.remove('js-navigationItem--opened');
            }
        });
        listItem.classList.toggle('js-navigationItem--opened');
    });
});
