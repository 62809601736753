import Choices from 'choices.js';

const selects = document.querySelectorAll('select.select--custom');
export const ChoicesInstances = [];
[].forEach.call(selects, (select) => {
    const instance = new Choices(select, {
        searchEnabled: false,
        placeholder: true,
        itemSelectText: '',
        shouldSort: false,
    });
    ChoicesInstances.push({instance, element: select});
});

