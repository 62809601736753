if (!window.location.origin) {
  (window.location as any).origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
}

const Polyfill = require.context('@/polyfills', true, /\.js$/);
Polyfill.keys().forEach(Polyfill);
// @ts-ignore
import * as log from 'loglevel';
import Vue from 'vue';
import store from './store';
import axios from 'axios';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
import './plugins/element.js';
import './js/assets.ts';
import './js/navigation.ts';
import '@/styles/main.scss';
import '@/assets/placeholder.jpg';
import './plugins/choices.js';
import './js/card-slider.ts';
import './js/image-slider.ts';
import './js/quantity-input.ts';
import './js/search.ts';
import './js/localized-search.ts';
import './js/list-filters.ts';
import './js/menu-responsive.ts';
import './js/clampify.ts';
import './js/checkbox-toggler.ts';
import './js/checkout-product-step.ts';
import './js/checkout-addresses-step.ts';
import './js/checkout-payment-step.ts';
import './js/profile-gift-card.ts';
import './js/profile-edit-addresses.ts';
import './js/product.ts';
import './js/tippy.ts';
import './js/cookies.js';
import './js/show-password.js';
import 'simplebar';

declare global {
  const log: any;
}

if (process.env.NODE_ENV !== 'production') {
  require('@config/theme.js');
}

const styleguide = require('@/styleguide.json');
const Styleguide = require('@bit/wurielle.pristine.vue-components.dss-styleguide').default;
const ElementQueries = require('css-element-queries/src/ElementQueries');



ElementQueries.listen();
// ElementQueries.init();

Vue.config.productionTip = false;

// new Vue({
//   store,
// }).$mount('#app');

const getStyleguide = document.querySelector('div#styleguide');
if (getStyleguide) {
  new Vue({
    components: {
      Styleguide,
    },
    data() {
      return {
        styleguide,
      };
    },
    template: `<Styleguide :styleguide="styleguide"></Styleguide>`,
  }).$mount('#styleguide');
}

