module.exports = function(container, buttonText, buttonId, fieldStart, allowDelete, functions, onDeleteFunctions) {
    if (!container.length > 0) {
        return;
    }

    if (buttonId === undefined) {
        buttonId = null;
    }

    if (fieldStart === undefined) {
        fieldStart = false;
    }

    if (allowDelete === undefined) {
        allowDelete = true;
    }

    if (functions === undefined) {
        functions = [];
    }

    if (onDeleteFunctions === undefined) {
        onDeleteFunctions = [];
    }

    // Define an index to count the number of added field (used to give name to fields)
    let index = container.contents().length;

    // If the index is > 0, fields already exists, then, add a deleteButton to this fields
    if (index > 0) {
        container.contents().each(function() {
            // placePrototypeInDiv($(this));
            addDeleteButton($(this));
            addFunctions($(this));
        });
    }

    // Delete the first label (the number of the field), and the required class
    container.contents().find('legend:first').text('');
    container.contents().find('legend:first').removeClass('required');
    container.contents().find('legend:first').removeClass('required');

    // Create and add a button to add new field
    let addButton = $('<a href="#" class="btn btn-secondary btn-sm"><span class="icon ion-md-add pr-mr-2 pr-text-lg"></span> '+buttonText+'</a>');

    // If needed, add the specified ti the button
    if (buttonId) {
        addButton.attr('id', buttonId);
    }

    if (false !== buttonText) {
        // Add the button at the end of the container
        container.append(addButton);
    }

    // Add a click event on the add button
    addButton.click(function(e) {
        e.preventDefault();
        // Call the addField method
        addField(container);
        return false;
    });

    // If we want to have a field at start
    if (true === fieldStart && 0 === index) {
        addField(container);
    }

    function addField(container, value = null) {
        let prototype = $(container.attr('data-prototype')
            .replace(/__name__label__/g, '')
            .replace(/__name__/g, index));
            
        if(value) $(prototype).find('input[type="text"]').val(value)
        // placePrototypeInDiv(prototype);
        addDeleteButton(prototype);
        addFunctions(prototype);
        addButton.before(prototype);
        // Increment the counter
        index++;
    }

    function addDeleteButton(prototype) {
        if (true === allowDelete) {
            // First, create the button
            let deleteButton = $('<div class="payment-checkout__giftCard__delete"><a href="#" class=""><span class="icon ion-md-close pr-mb-0 pr-inline-flex pr-items-center pr-justify-center pr-w-4 pr-h-4"></span></a></div>');
            // Add the button on the field
            prototype.append(deleteButton);

            // Create a listener on the click event
            deleteButton.find('a').click(function(e) {
                e.preventDefault();

                // Remove the field
                prototype.remove();

                // Call onDeleteFunctions
                callOnDeleteFunctions(prototype);

                return false;
            });
        }
    }

    function addFunctions(prototype) {
        // If there are supplementary functions
        if (functions.length > 0) {
            // Do a while on functions, and apply them to the prototype
            for (let i = 0; functions.length > i; i++) {
                functions[i](prototype);
            }
        }
    }

    function callOnDeleteFunctions(prototype) {
        // If there are supplementary functions
        if (onDeleteFunctions.length > 0) {
            // Do a while on functions, and apply them to the prototype
            for (let i = 0; onDeleteFunctions.length > i; i++) {
                onDeleteFunctions[i](prototype);
            }
        }
    }

    function placePrototypeInDiv(prototype)
    {
        // Get the content of the prototype, and save it
        let content = prototype.contents();

        // Add a div row
        let row = $('<div class="row">');
        prototype.prepend(row);

        // Add a div column
        let col = $('<div class="col-sm-11">');
        row.prepend(col);

        // Move the prototype content in the new column
        content.appendTo(col);
    }


    function addFieldWithValue(value) {
        addField(container, value)
    }
    
    return { addFieldWithValue }
};
