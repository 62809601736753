const quantityInputs = document.querySelectorAll('[data-quantity-input]');
[].forEach.call(quantityInputs, (quantityInput: any) => {
    const id = quantityInput.getAttribute('data-quantity-input');
    const input = quantityInput.querySelector('input[type=number]');
    const min = input.getAttribute('min') ? Number(input.getAttribute('min')) : null;
    const max = input.getAttribute('max') ? Number(input.getAttribute('min')) : null;
    const buttonQuantityDown = document.querySelector(`[data-quantity-down="${id}"]`);
    const buttonQuantityUp = document.querySelector(`[data-quantity-up="${id}"]`);
    if (buttonQuantityDown) {
        buttonQuantityDown.addEventListener('click', () => {
            const targetValue = Number(input.value) - 1;
            if (min === null || targetValue >= min) {
                input.value = targetValue;
                input.setAttribute('value', targetValue.toString());
                input.dispatchEvent(new Event('change'));
            }
        });
    }
    if (buttonQuantityUp) {
        buttonQuantityUp.addEventListener('click', () => {
            const targetValue = Number(input.value) + 1;
            if (max === null || targetValue <= max) {
                input.value = targetValue;
                input.setAttribute('value', targetValue.toString());
                input.dispatchEvent(new Event('change'));
            }
        });
    }
});


