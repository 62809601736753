































































































































































































































































/// <reference path="styleguide.d.ts"/>

import {

    Vue,

    Component,

    Watch,

    Prop

} from "vue-property-decorator";



import { codemirror } from 'vue-codemirror';

import 'codemirror/lib/codemirror.css';

import 'codemirror/theme/monokai.css';

import 'codemirror/mode/htmlmixed/htmlmixed.js';

@Component({

    components: {

        codemirror,

    },

})

export default class StyleguideItem extends Vue {

    @Prop({required: true}) block: any;

    active: any = false;

    cmOptions: any = {

        tabSize: 4,

        styleActiveLine: true,

        mode: 'htmlmixed',

        theme: 'monokai',

        lineNumbers: true,

        line: true,

        readOnly: true,

        lineWrapping: true,

    };



    escapeClass(className: string) {

        return className.replace(/\./g, ' ');

    }

}

