import axios from 'axios';
import Clampify from '@/js/clampify';
// @ts-ignore
import Timeout from 'smart-timeout';
const searchBar = document.querySelector('.js-searchBar');
const search = document.querySelector('.js-search');
const searchResult = document.querySelector('.js-searchResult');
let searchInput: any;
if (search) {
    searchInput = search.querySelector('.js-searchInput');
}

const apiSearch = (searchValue: string) => {
    searchValue = searchValue || searchInput ? searchInput.value : '';
    if (searchValue) {
        axios.get(`${window.location.origin}/search?query=${encodeURI(searchValue)}`)
            .then((response) => {
                if (searchResult) {
                    searchResult.innerHTML = response.data;
                    Clampify('.js-searchResult');
                }
                (search as any).classList.remove('js-search--loading');
            })
            .catch((err) => {
                log.error(err);
                (search as any).classList.remove('js-search--loading');
            });
        (search as any).classList.add('js-search--loading');
    }
};
const toggleSearch = () => {
    if (search) {
        search.classList.toggle('js-search--visible');
        if (search.classList.contains('js-search--visible')) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

    }
};

if (searchBar && search) {
    const searchButton = search.querySelector('.js-searchButton');
    const searchClose = search.querySelector('.js-searchClose');
    searchBar.addEventListener('click', toggleSearch);
    if (searchClose) {
        searchClose.addEventListener('click', toggleSearch);
    }
    (searchInput as any).addEventListener('keyup', (e: any) => {
        if (searchInput.value.length > 2) {
            if (Timeout.exists('search')) {
                Timeout.clear('search');
            }
            if (e.which === 13) {
                apiSearch((searchInput as any).value);
            } else {
                Timeout.set('search', apiSearch, 800);
            }
        }
    });
    (searchButton as any).addEventListener('click', () => {
        apiSearch((searchInput as any).value);
    });
}



