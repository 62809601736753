































import {

    Vue,

    Component,

    Watch,

    Prop

} from "vue-property-decorator";

import * as _ from 'lodash';

@Component({

    name: 'StyleguideTreeMenu',

    components: {

    },

})

export default class StyleguideTreeMenu extends Vue {

    @Prop({required: true}) nodes: any;

    @Prop({required: true}) sections: any;

    @Prop({required: true}) level: any;

    @Prop() value: any;



    getLinks(level: number = 0, parentID: number) {

        return _.filter(this.sections, (section: any) => {

            if(typeof parentID) {

                return section.level === level && section.parent === parentID;

            }

            return section.level === level;

        });

    }



    getSelected(id: any) {

        const selected = _.find(this.sections, (section: any) => section.id === this.value);

        if (this.value !== null) {

            return this.value === id || _.includes(selected.parents, id);

        }

        return false;

    }

}

