const togglerClassOn = 'js-toggler-on';
const togglerClassOff = 'js-toggler-off';
[].forEach.call(document.querySelectorAll('[data-toggler-trigger]'), (element: any) => {
    const id = element.getAttribute('data-toggler-trigger');
    const checkbox = element.querySelector('input[type="checkbox"]');
    const target = document.querySelector(`[data-toggler-target="${id}"]`);
    if (checkbox && target) {
        if (checkbox.checked) {
            target.classList.add(togglerClassOn);
            target.classList.remove(togglerClassOff);
        } else {
            target.classList.add(togglerClassOff);
            target.classList.remove(togglerClassOn);
        }
    }
    checkbox.addEventListener('change', () => {
        if (checkbox && target) {
            if (checkbox.checked) {
                target.classList.add(togglerClassOn);
                target.classList.remove(togglerClassOff);
            } else {
                target.classList.add(togglerClassOff);
                target.classList.remove(togglerClassOn);
            }
        }
    });
});
