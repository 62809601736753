import '@/styles/main.scss';
import 'swiper/dist/css/swiper.min.css';

// ----- Generic images
import '@/assets/favicon.png';
import '@/assets/logo_horizontal_white.svg';
import '@/assets/logo_background_blue.svg';
import '@/assets/logo_vertical_white.svg';
import '@/assets/logo_background.svg';
import '@/assets/placeholder.jpg';
import '@/assets/menu_bons.svg';
import '@/assets/user_profil.jpg';
import '@/assets/logo_vertical.svg';
import '@/assets/security_background.jpg';
import '@/assets/hero.jpg';

// ----- Icons
import '@/assets/dropdown_account.svg';
import '@/assets/dropdown_building.svg';
import '@/assets/power_off.svg';

import '@/assets/promotions.svg';
import '@/assets/themes.svg';
import '@/assets/last_offres.svg';
import '@/assets/truck.svg';
import '@/assets/ebillet.svg';

import '@/assets/account.svg';
import '@/assets/panier.svg';
import '@/assets/search.svg';
import '@/assets/search-white.svg';
import '@/assets/poubelle.svg';

import '@/assets/menu_movi.svg';
import '@/assets/menu_parc.svg';
import '@/assets/menu_show.svg';
import '@/assets/menu_spor.svg';
import '@/assets/menu_voya.svg';
import '@/assets/menu_call_bg.svg';

import '@/assets/bank_check.svg';
import '@/assets/bank_transfer.svg';
import '@/assets/card.svg';
import '@/assets/invoice.svg';
import '@/assets/logo_paypal.svg';
// import '@/assets/paypal.svg';
import '@/assets/ancv.svg';
import '@/assets/map.svg';