// @ts-ignore
import $ from 'jquery';

export default class ProfileGiftCard {
    constructor() {
        this.initForm();
    }

    private initForm() {
        this.handleCardCodeKeyUp($('#gift_card_checker'))
    }

    public handleCardCodeKeyUp(container: any) {
        let field = container.find('#gift_card_checker_giftCard');
        field.on('input', () => {
            let code = field.val().split("-").join("").toUpperCase(); // remove hyphens

            if (code.length > 0) {
                code = code.match(new RegExp('.{1,4}', 'g')).join("-");
            }
            field.val(code);
        });
    }
}
new ProfileGiftCard();
